import React, { CSSProperties, ReactElement } from 'react';
import { Client } from 'models/client.model';
import cn from 'classnames';
import { config } from 'config';
import { Button, Card, Collapse, Input, SelectCheckbox, Spinner } from 'ui-kit';
import { emptyStringIfNull, getMaskPhone, ICONS } from 'common/helpers';
import { ETranslations } from 'types/translates';
import { TAGS_TITLES } from '../../../constants';
import { TagsById } from 'components/Tags';
import { ClientInfoPopup } from 'components/ClientInfoPopup';
import useSelectGuestFromList from './useSelectGuestFromList';
import styles from './select-guest-from-list.module.scss';

type SelectGuestFromListProps = {
  style?: CSSProperties;
  onClose?: () => void;
  onSelectGuest: (guest: Client) => void;
  controls?: ReactElement;
  onOneGuestLeft?: any;
};

export const SelectGuestFromList: React.FC<SelectGuestFromListProps> = ({
  style,
  onClose,
  onSelectGuest,
  controls,
  onOneGuestLeft,
}) => {
  const {
    isLoading,
    intl,
    getIntlChooseEntity,
    getFromFilter,
    tagsOptions,
    handleOnInputTerm,
    handleScroll,
    handleTagsChange,
    guests,
  } = useSelectGuestFromList({ onClose, onOneGuestLeft });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Card style={style} onClose={onClose}>
          <Card.Header
            title={intl.formatMessage({ id: ETranslations.GUEST_LIST })}
            controls={controls}
          >
            <div className={styles.subHeader}>
              <SelectCheckbox
                className={styles.filter}
                placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
                value={getFromFilter()}
                // @ts-ignore
                onChange={handleTagsChange}
                options={tagsOptions}
                titles={TAGS_TITLES}
              />

              <Input.Search
                onSearch={handleOnInputTerm}
                className={styles.filter}
              />
            </div>
          </Card.Header>
          <Card.Content onScroll={handleScroll} style={{ overflowY: 'auto' }}>
            <div>
              {guests?.map((g: Client) => (
                <Collapse
                  headerClassName={styles.guest}
                  onClick={() => onSelectGuest(g)}
                  key={g.client_id}
                  header={
                    <section
                      className={cn('guest-card', 'view', 'variant-B')}
                      style={{ width: '100%', border: 'none' }}
                    >
                      <div className={styles.wrapper}>
                        <div className={cn('badge')}>
                          <div className="data">
                            <div className="loyalty-bar null" />
                            <ICONS.USER_PHONE />
                            <h3 className="name">
                              {emptyStringIfNull([
                                g.name,
                                g.middle_name as string,
                                g.surname,
                              ])}
                            </h3>
                            <span className="phone">
                              {getMaskPhone(g.phone)}
                            </span>
                            {config.vipSign && g.vip && (
                              <img
                                className="star"
                                src={ICONS.StarA}
                                alt="vip"
                              />
                            )}
                            {g.tags && g.tags?.length !== 0 && (
                              <div className={styles.tags}>
                                <TagsById tagsIDs={g.tags} />
                              </div>
                            )}
                          </div>
                        </div>
                        <ClientInfoPopup
                          clientId={g.client_id}
                          placement="auto"
                        >
                          <Button
                            variant="phantom"
                            type="button"
                            className={styles.guestDetail}
                          >
                            <ICONS.Question />
                          </Button>
                        </ClientInfoPopup>
                      </div>
                    </section>
                  }
                ></Collapse>
              ))}
            </div>
          </Card.Content>
        </Card>
      )}
    </>
  );
};
