import {
  Children,
  cloneElement,
  isValidElement,
  memo,
  ReactElement,
} from 'react';
import cn from 'classnames';
import { Button, Tag } from 'ui-kit';
import { ICONS } from 'common/helpers';
import { Booking, BookingOrder, ManagerialTable } from 'types/booking';
import styles from './BookingCard.module.scss';
import { ClientInfoPopup } from 'components/BookingInfoPopup';
import { BookingClientInfo } from './BookingClientInfo';
import { isBooking, stopPropagation } from 'utils';
import { BookingVisitInfo } from './BookingVisitInfo';
import { VisitProvider } from './VisitContext';
import useBookingCard from './hooks/useBookingCard';
import { BookingHeader } from './BookingHeader';
import moment from 'moment';

export interface BookingCardViewProps {
  compact?: boolean;
  withDate?: boolean;
  showPhone?: boolean;
  showStatus?: boolean;
}

export interface BookingCardProps<
  T extends Booking | BookingOrder | ManagerialTable
> extends BookingCardViewProps {
  booking: T;
  active?: boolean;
  onClick?: (booking: T) => void;
  className?: string;
  actions?:
    | ReactElement<{ className?: string }>
    | Array<ReactElement<{ className?: string }>>;
  canDrag?: boolean;
  isDashboard?: boolean;
}

const Card = <T extends Booking | BookingOrder | ManagerialTable>({
  booking,
  active,
  onClick,
  actions,
  className,
  compact,
  withDate,
  showPhone = true,
  showStatus = true,
  canDrag = true,
  isDashboard,
}: BookingCardProps<T>) => {
  const {
    handleClick,
    isExpired,
    isLate,
    label,
    statusClassName,
    statusClassNameManagement,
    phone,
    isManagerialTableBoolean,
    handleTouchStart,
    handleTouchEnd,
    drag,
    dragPreview,
    isAnonym,
    isEndingSoon,
  } = useBookingCard({ booking, onClick, canDrag });
  const isStatus = showStatus && isBooking(booking);
  const isPhone = Boolean(
    (showPhone && phone)
      || (isBooking(booking) && booking.tags.length)
      || booking.source
  );

  return (
    <div
      onMouseDown={handleTouchStart}
      onMouseLeave={handleTouchEnd}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      ref={drag}
      aria-label={label}
      onClick={handleClick}
      className={cn(
        styles.bookingCard,
        styles[statusClassName],
        styles[statusClassNameManagement],
        className,
        {
          [styles.active]: active,
          [styles.interactive]: !!onClick,
          [styles.expired]: isExpired,
          [styles.late]: isLate,
          [styles.noStatusBorder]: !showStatus,
        }
      )}
    >
      <div className={styles.content}>
        <BookingHeader
          styles={styles}
          compact={compact}
          status={isStatus && (booking as Booking).status}
          extraStatus={isStatus && (booking as Booking).extraStatus}
          phone={isPhone && phone}
          useDeposit={booking.useDeposit}
          source={booking.source}
          isDashboard={isDashboard}
          isEndingSoon={isEndingSoon}
          tags={isBooking(booking) ? booking.tags : undefined}
        >
          <BookingClientInfo
            client={booking.client || booking.contact}
            isManagerialBooking={booking?.seatType === 'MANAGER_BOOK'}
            ref={dragPreview}
          />
        </BookingHeader>
        <BookingVisitInfo
          isManagerialTableBoolean={isManagerialTableBoolean}
          bookingTime={booking.bookingTime.replace(/(\d+:\d+).*/, '$1')}
          places={booking.places}
          persons={booking.persons}
          isOverbooking={isBooking(booking) && booking.isOverbooking}
          comment={booking.comment}
          compact={compact}
          date={withDate ? booking.bookingDate : undefined}
        />
      </div>
      <div className={styles.actions} onClick={stopPropagation}>
        {Children.map(actions, (el) => {
          if (isValidElement(el)) {
            return cloneElement(el, {
              className: cn(el.props.className, styles.action),
            });
          }
          return null;
        })}
        {!isManagerialTableBoolean && !isAnonym && (
          <ClientInfoPopup
            clientId={booking?.client?.client_id || booking?.contact?.id}
            placement="auto"
          >
            <Button variant="phantom" type="button" className={styles.userInfo}>
              <ICONS.Question />
            </Button>
          </ClientInfoPopup>
        )}
      </div>
    </div>
  );
};

export const BookingCard = memo(
  (props: BookingCardProps<Booking | BookingOrder | ManagerialTable>) => (
    <VisitProvider booking={props.booking}>
      <Card {...props} />
    </VisitProvider>
  )
) as typeof Card;
