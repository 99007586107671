import {Card} from "ui-kit";
import {BookingsList} from "components/BookingsList";
import {HallSchema} from "components/hall-scheme/redux/HallSchemaV2/hall-schema";
import {EditBooking} from "components/hall-scheme/redux/TableBookingList/table-booking-list";
import {HideWhen} from "components/HideWhen";
import {Modal} from "components/modal";
import {Pagination} from "components/Pagination";
import {useLazyGlobalSearchQuery} from "features/api/bookings-api";
import {globalSearchSelectors, useGlobalSearchActions} from "features/GlobalSearch";
import {SelectGuestFromList} from "features/GuestsList/components/select-guest-from-list";
import {HallMode, useHallSchemaActions} from "features/HallSchema";
import {fromProxySelectors, useFromProxyActions} from "features/BookingFormProxy";
import {hallModeSelector} from "features/HallSchema/selectors";
import {useCallback, useEffect, useMemo} from 'react';
import {useSelector} from "react-redux";
import {Booking} from "types/booking";
import {SearchContext} from "types/globalSearch";
import cn from "classnames";

import styles from "./Search.module.scss";
import {Client} from "types/client";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {getBookingStartTime} from '../../utils';
import {useTimelineActions} from '../../features/Timeline';
import {useApplicationContextActions} from '../../features/AppContex';
import moment from 'moment';
import {noop} from 'lodash'

const EMPTY_LIST: Booking[] = [];

export function Search() {
  const {getIntlEntityEdition, isRussianLocale, intl} = useIntlUtils();
  const params = useSelector(globalSearchSelectors.params);
  const skip = useMemo(() => {
    if (params.context === SearchContext.CLIENT_TAG) return params.keyword.length < 1;
    return params.keyword.length < 3
  }, [params.context, params.keyword]);
  const [fetchSearch, {data, isFetching}] = useLazyGlobalSearchQuery()
  const {setPage} = useGlobalSearchActions();
  const {setClient, setOnlyBooking: setBooking} = useFromProxyActions();
  const booking = useSelector(fromProxySelectors.selectBooking);
  const {setTime, resetTimeShift} = useTimelineActions()
  const {setDate, setPlaceFromBooking} = useApplicationContextActions();

  const mode = useSelector(hallModeSelector);
  const {switchMode} = useHallSchemaActions();

  useEffect(() => {
    if(skip) return noop
    const result = fetchSearch(params)

    return result.abort
  }, [skip, params])

  const closeHall = useCallback(() => switchMode(HallMode.TABLES), [switchMode]);
  const closeGuests = useCallback(() => switchMode(HallMode.TABLE_BOOKINGS_EDIT), [switchMode]);

  const clearBooking = useCallback(() => {
    setBooking(undefined);
    resetTimeShift();
    setDate(moment())
    closeHall();
  }, []);


  const handleSetClient = useCallback((client: Client) => {
    setClient({client});
    closeGuests();
  }, [setClient, closeGuests]);


  const onSelectBook = useCallback((book: Booking) => {
      setDate(moment(book.bookingDate));
      setTime(getBookingStartTime(book));
      setBooking(book);
      setPlaceFromBooking(book);
  }, []);

  return (
    <div className={styles.container}>
      <BookingsList
        bookings={data?.content || EMPTY_LIST}
        loading={isFetching}
        onClick={onSelectBook}
        compact
        withDate
      />
      <Pagination
        className={styles.paging}
        total={data?.totalElements ?? 0} currentPage={params.page}
        pageSize={params.size} onChange={setPage}
      />
      <Modal
        isOpen={Boolean(booking.bookingId)}
        onClose={clearBooking}
        title={getIntlEntityEdition(isRussianLocale ? ETranslations.PLURAL_BOOKINGS_NOM : ETranslations.PLURAL_BOOKING)}
      >
        <Modal.Content noPadding className={styles.modal}>
          <HideWhen condition={mode.includes('HALL') || mode.includes('GUEST')} noUnmount>
            <div>
              {booking.bookingId && <EditBooking bookingId={(booking as Booking).bookingId} hideCard />}
            </div>
          </HideWhen>
          <HideWhen condition={!mode.includes('HALL')}>
            <Card onClose={closeHall}>
              <Card.Header title={intl.formatMessage({id: ETranslations.HALL_SCHEME})} />
              <Card.Content className={styles.scheme}>
                <HallSchema />
              </Card.Content >
            </Card>
          </HideWhen>
        <HideWhen condition={![HallMode.BOOKING_GUEST, HallMode.TABLE_BOOKINGS_EDIT_GUEST].includes(mode)}>
          <div className={cn(styles.guests)}>
            <SelectGuestFromList
              onClose={closeGuests}
              onSelectGuest={handleSetClient}
            />
          </div>
        </HideWhen>
        </Modal.Content>
      </Modal>
    </div>
  );
}
