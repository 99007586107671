import { useCallback, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { getBookingStartTime, isManagerialTable } from 'utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Booking, ManagerialTable } from 'types/booking';
import { HallMode, useHallSchemaActions } from 'features/HallSchema';
import { useFromProxyActions } from 'features/BookingFormProxy';
import { useCancelManageralTableMutation } from 'features/api/managerialTables';
import { useMoveBookingActions } from 'features/MoveBooking';
import { useTimelineActions } from 'features/Timeline';
import {
  useChangeBookingStatus,
  useGetBookingExtraStatusesQuery,
  useGetBookingStatusQuery,
  usePrintBookingInfo,
} from 'features/api/bookings-api';
import { useBooleanState } from 'hooks/useBooleanState';
import { useRecoveryBooking } from 'hooks/useRecoveryBooking';
import { useIntlUtils } from '../../../hooks/useIntlUtils';
import { PopupRef } from 'components/Popup';
import { useAllStatuses } from 'features/api/dictionaries-api';
import type { ExtraStatus } from 'types/status';

const useBookingActions = (
  booking: Booking | ManagerialTable,
  onEdit?: (booking: Booking | ManagerialTable) => void
) => {
  const { intl } = useIntlUtils();
  const { recovery } = useRecoveryBooking(booking);
  const { reset: closeEdit } = useFromProxyActions();
  const { switchMode, setActiveTables } = useHallSchemaActions();
  const { setTimestamp, setTime, setVisistTime } = useTimelineActions();

  const [print] = usePrintBookingInfo(booking.bookingId);

  const [wasOpen, setWasOpen] = useBooleanState(false);
  const [cancel] = useCancelManageralTableMutation();

  const popup = useRef<PopupRef>(null);

  const { data: status, isLoading } = useGetBookingStatusQuery(
    wasOpen ? booking.bookingId ?? skipToken : skipToken
  );

  // Реализация убрана пока модель экстра статусов еще простая. При потребности - вернуть, но избавиться от слишком частых запросов
  //const { data: extraStatuses } = useGetBookingExtraStatusesQuery(booking.bookingId ?? NaN)
  const { data: statuses } = useAllStatuses();
  const extraStatuses = statuses.filter(
    (s) => s.is_extra && s.is_active && s.category === booking.status?.category
  ) as ExtraStatus[];

  const {
    force,
    nextStatus: goNextStatus,
    terminalStatus,
    resetForce,
  } = useChangeBookingStatus(booking.bookingId);
  const handleEdit = useCallback(() => {
    onEdit?.(booking as Booking);
    popup.current?.close();
  }, [booking, onEdit]);

  const nextStatus = useMemo(() => status?.next || status?.terminal, [status]);

  const handleStatusChange = useCallback(async () => {
    try {
      popup.current?.close();
      // eslint-disable-next-line no-unused-expressions
      status?.next ? goNextStatus() : terminalStatus();
    } catch {
      //
    }
  }, [status]);

  const { selectMoveSource, selectSourceTableNumber } = useMoveBookingActions();
  const tableNumber = booking.places.map((place) => place.number);
  const handleMoveClick = useCallback(() => {
    setTime(getBookingStartTime(booking));
    booking.bookingId && selectMoveSource(booking.bookingId);
    selectSourceTableNumber(tableNumber);
  }, [booking]);

  const [popupState, setPopupState] = useState({
    isCancel: false,
    isStatus: false,
  });

  const hide = () =>
    setPopupState({
      isCancel: false,
      isStatus: false,
    });

  const showStatus = () =>
    setPopupState({
      isCancel: false,
      isStatus: true,
    });

  const showCancel = () =>
    setPopupState({
      isCancel: true,
      isStatus: false,
    });

  const handlePrint = useCallback(() => {
    print();
    popup.current?.close();
  }, [print]);

  const createBooking = () => {
    switchMode(HallMode.MANAGERAL_CREATE_BOOKING);
    const tables = booking.places.map((each) => each.id);
    setActiveTables({ activeTables: tables });
    setTimestamp(
      dayjs(`${booking.bookingDate} ${booking.bookingTime}`).valueOf()
    );
    closeEdit();

    if (isManagerialTable(booking)) {
      //@ts-ignore
      setVisistTime(booking.visitTime);
    }
  };

  const isManagerialTableAndTimePassed = useMemo(() => {
    if (
      isManagerialTable(booking)
      && dayjs().valueOf()
        >= dayjs(`${booking.bookingDate}: ${booking.bookingTime}`)
          .add(booking.visitTime, 'minute')
          .valueOf()
    ) {
      return true;
    } else {
      return false;
    }
  }, [booking]);

  return {
    isLoading,
    handleEdit,
    intl,
    isManagerialTableAndTimePassed,
    createBooking,
    cancel,
    handleMoveClick,
    setTimestamp,
    nextStatus,
    handleStatusChange,
    recovery,
    handlePrint,
    isCancel: popupState.isCancel,
    showCancel,
    isStatus: popupState.isStatus,
    showStatus,
    hide,
    popup,
    setWasOpen,
    force,
    goNextStatus,
    resetForce,
    extraStatuses,
  };
};

export default useBookingActions;
