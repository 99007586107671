import {useCallback, useMemo} from 'react';
import {config} from 'config';
import {useDrag} from 'react-dnd';
import {
  formatPhone,
  isBookingOrder,
  isManagerialTable,
} from 'utils';
import {useVisitContext} from '../VisitContext';
import {EDraggableEntity} from '../../../constants';
import {useTap} from 'hooks/useTap';
import {Booking, BookingOrder, ManagerialTable} from 'types/booking';
import moment from 'moment';

const useBookingCard = ({
  booking,
  onClick,
  canDrag,
}: {
  booking: Booking | BookingOrder | ManagerialTable;
  onClick: any;
  canDrag: boolean;
}) => {
  const handleClick = useCallback(() => onClick?.(booking), [onClick, booking]);

  const {isExpired, isLate, statusClassName, statusClassNameManagement}
    = useVisitContext();

  const label = useMemo(
    () =>
      (isBookingOrder(booking)
        ? ['order', booking.orderId]
        : ['booking', booking.bookingId]
      ).join(' '),
    [booking]
  );

  const isAnonym = booking?.contact?.contact_type === 'CONTACT';
  const phone = useMemo(() => formatPhone(booking?.client?.phone), [booking]);

  const isManagerialTableBoolean = useMemo(
    () => isManagerialTable(booking),
    [booking]
  );

  const {handleTouchStart, handleTouchEnd, longTap} = useTap(1000);

  const [, drag, dragPreview] = useDrag(
    () => ({
      type: EDraggableEntity.BOOKING_CARD,
      item: booking,
      canDrag: config.dragAndDrop && canDrag && longTap,
    }),
    [booking, longTap]
  );

  const isEndingSoon
    = moment(`${booking.bookingDate} ${booking.bookingTime}`, 'YYYY-MM-DD HH:mm')
      .add(booking.visitTime, 'minutes')
      .diff(moment(), 'minutes') <= 20;

  return {
    handleClick,
    isExpired,
    isLate,
    label,
    statusClassName,
    statusClassNameManagement,
    phone,
    isManagerialTableBoolean,
    handleTouchStart,
    handleTouchEnd,
    drag,
    dragPreview,
    isAnonym,
    isEndingSoon,
  };
};

export default useBookingCard;
